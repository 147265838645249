import { useLocation } from '@reach/router';
import * as React from 'react';

import { scrollMemoPaths } from './scroll-memo-paths';

const useMemoizedPos = (): ((path: string) => number) => {
  return (path: string): number => {
    return scrollMemoPaths?.[path] ?? 0;
  };
};

const PROFILE_TABS_PATH_REGEX =
  /\/(xingternal\/)?profile\/[^/]+\/(web_profiles|portfolio)/;
const isProfileTabsPath = (path: string): boolean => {
  return path.match(PROFILE_TABS_PATH_REGEX) !== null;
};

export const ScrollManager: React.FC = () => {
  const [previousPath, setPreviousPath] = React.useState<string>('');

  const location = useLocation();
  const getMemoizedPos = useMemoizedPos();

  React.useEffect(() => {
    if (location.pathname !== previousPath) {
      setPreviousPath(location.pathname);
      const pos = getMemoizedPos(location.pathname);

      if (
        isProfileTabsPath(location.pathname) &&
        isProfileTabsPath(previousPath)
      ) {
        return;
      }

      window.scrollTo(0, pos || 0);
    }
  }, [location, getMemoizedPos, previousPath, setPreviousPath]);

  return null;
};
